<template>
  <div style="height: inherit">

    <b-overlay :show="showLoading" spinner-variant="primary">

      <!-- ECommerce Header -->
      <section id="ecommerce-header">
        <div class="row">
          <div class="col-sm-12">
            <div class="ecommerce-header-items">
              <div class="result-toggler">
                <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="28"
                  @click="mqShallShowLeftSidebar = true" />
                <div class="search-results">
                  {{ totalProducts }} articles trouvés
                </div>
              </div>

              <div class="view-options d-flex">

                <!-- Sort Button -->
                <b-dropdown :text="sortBy.text" right variant="outline-primary">
                  <b-dropdown-item v-for="sortOption in sortByOptions" :key="sortOption.value"
                    @click="sortBy = sortOption">
                    {{ sortOption.text }}
                  </b-dropdown-item>
                </b-dropdown>

                <!-- Item View Radio Button Group  -->
                <b-form-radio-group v-model="itemView" class="ml-1 list item-view-radio-group" buttons size="sm"
                  button-variant="outline-primary">
                  <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                    <feather-icon :icon="option.icon" size="18" />
                  </b-form-radio>
                </b-form-radio-group>

              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Searchbar -->
      <div class="ecommerce-searchbar mt-1">
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input v-model="filters.q" placeholder="Search Product" class="search-product"
                @input="updateSearchQuery" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <!-- Prodcuts -->
      <section :class="itemView">
        <b-card v-for="(product, index) in products" :key="index" class="ecommerce-card" no-body>
          <div class="item-img text-center product-content">
            <b-link>

              <div @click="showDetails(product)">
                <b-img v-if="product.defImage == undefined || product.defImage == null || product.defImage == ''"
                  :alt="`${product.description}-${product.code}`" fluid class="card-img-top product-img"
                  :src="require('@/assets/images/default.jpg')" />
                <b-img v-else :alt="`${product.description}-${product.code}`" fluid class="card-img-top product-img"
                  :src="$serverpath + product.defImage" />
              </div>

            </b-link>
          </div>
          <!-- Product Details -->
          <b-card-body @click="showDetails(product)">
            <h6 class="item-name">
              <b-link class="text-body">
                {{ product.description }}
              </b-link>
              <b-card-text class="item-company">
                By <b-link class="ml-25">
                  {{ product.vendorDescription }}
                </b-link>
              </b-card-text>
            </h6>
            <b-card-text class="item-description">
              {{ product.familyDescription }}
              - {{ product.subFamilyDescription }}
            </b-card-text>
          </b-card-body>

          <!-- Product Actions -->
          <div class="item-options text-center">
            <div class="item-wrapper">
              <div class="item-cost">
                <h4 class="item-price">
                  <code v-if="product.price == undefined" class="shimmer-line"
                    style="color : #7367f0;animation: shimmer 10s linear infinite;padding-right: 150px;"></code>
                  <b-badge v-else pill variant="light-primary" class="badge-glow">{{ formatPrice(product.price) }}
                    TND</b-badge>
                </h4>
              </div>
            </div>
          </div>
        </b-card>
      </section>

      <!-- Pagination -->
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination v-model="currentPgae" :total-rows="totalProducts" :per-page="perPage" first-number
              align="center" last-number prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>

      <!-- Sidebar -->
      <portal to="content-renderer-sidebar-detached-left">
        <shop-left-filter-sidebar :filters="filters" :filter-options="filterOptions" :showLoading="showLoading"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" @triggerFilter="handleEventFilter" />
      </portal>

    </b-overlay>
  </div>
</template>

<script>
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './CatalogFilterSidebar.vue'
import { formatPrice } from "@/utils/formatting";

export default {
  components: {
    // SFC
    ShopLeftFilterSidebar,
  },
  data() {
    return {
      product: {
        Description: ""
      },
      formatPrice: formatPrice,
      currentPgae: 1,
      perPage: 9,
      showLoading: true,
      allProductsWithoutFilters: [],
      allProducts: [],
      products: [],
      totalProducts: 0,
      sortBy: { text: 'Trier Par', value: '' },
      sortByOptions: [
        { text: 'Description A-Z', value: 'description-asc' },
        { text: 'Description Z-A', value: 'description-desc' },
      ],
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      filters: {
        q: '',
        priceRangeDefined: 'Tous',
        categories: 'Tous',

        ratings: 1,
        pages: "Arrivages",
        subCategory: "Tous"
      },
      filterOptions: {
        priceRangeDefined: [
          { text: 'Tous', value: 'Tous' },
          { text: '<= 10 TND', value: '<=10' },
          { text: '10 TND - 30 TND', value: '10-30' },
          { text: '30 TND - 50 TND', value: '30-50' },
          { text: '>= 50 TND', value: '>=50' },
        ],
        categories: [
        ],
        subCategories: [
        ],
        pages: [
          "Arrivages",
          "Nouveautés",
          "Promotions",
          "Répartitions"
        ],
        ratings: [
          { rating: 1, value: 1 },
          { rating: 3, value: 3 },
          { rating: 4, value: 4 },
        ],
      }
    }
  },
  watch: {
    currentPgae(val) {
      this.showLoading = true;
      this.products = this.allProducts.slice((val - 1) * this.perPage, this.perPage * val)
      this.showLoading = false;
    },
    async sortBy(sortByValue) {
      this.showLoading = true;
      await this.sortByValue(sortByValue);
      this.showLoading = false;
    }
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const defaultImage = require('@/assets/images/default.jpg');
    return {
      mqShallShowLeftSidebar,
      defaultImage,
    }
  },
  methods: {
    async showDetails(data) {
      this.$router.push({ name: 'supplier-item-sheet', params: { code: data.code } });
    },
    updateSearchQuery() {
      if (this.filters.q.trim() == "") {
        this.handleEventFilter(this.filters)
      } else {
        let search = this.filters.q;
        this.handleEventFilter(this.filters)
        this.filters.q = search;
        let filteredItems = this.allProducts.filter(item => item.description.toLowerCase().includes(this.filters.q.toLowerCase()));
        setTimeout(() => {
          this.totalProducts = filteredItems.length;
          this.allProducts = filteredItems;
          this.products = filteredItems.slice(0, 9);
        }, 500);
      }
    },
    async sortByValue(sortByValue) {
      switch (sortByValue.value) {
        case 'description-asc':
          this.allProducts.sort((a, b) => a.description.localeCompare(b.description));
          this.products = this.allProducts.slice(0, 9);
          break;
        case 'description-desc':
          this.allProducts.sort((a, b) => b.description.localeCompare(a.description));
          this.products = this.allProducts.slice(0, 9);
          break;
        case 'price-asc':
          this.allProducts.sort((a, b) => a.Unit_Price_pharmacien_TTC - b.Unit_Price_pharmacien_TTC);
          this.products = this.allProducts.slice(0, 9);
          break;
        case 'price-desc':
          this.allProducts.sort((a, b) => b.Unit_Price_pharmacien_TTC - a.Unit_Price_pharmacien_TTC);
          this.products = this.allProducts.slice(0, 9);
          break;
        case 'classification-asc':
          this.allProducts.sort((a, b) => a.Matrice.localeCompare(b.Matrice));
          this.products = this.allProducts.slice(0, 9);
          break;
        case 'classification-desc':
          this.allProducts.sort((a, b) => b.Matrice.localeCompare(a.Matrice));
          this.products = this.allProducts.slice(0, 9);
          break;
      }
    },
    async handleEventFilter(data) {
      this.mqShallShowLeftSidebar = false;
      this.filters.q = "";
      this.showLoading = true;
      this.allProducts = this.allProductsWithoutFilters;
      await this.filterBySubFamily(data);
      this.totalProducts = this.allProducts.length;
      this.products = this.allProducts.slice(0, 9);
      this.showLoading = false;
    },
    async filterBySubFamily(data) {
      this.mqShallShowLeftSidebar = false;
      if (data.subCategory != "Tous")
        this.allProducts = this.allProductsWithoutFilters.filter(item => item.subFamilyDescription === data.subCategory);
    },
    async filterByPrice(data) {
      this.mqShallShowLeftSidebar = false;
      switch (data.ratings) {
        case 3:
          this.allProducts = this.allProducts.filter(item => item.Matrice.startsWith('A') || item.Matrice.startsWith('B'));
          break;
        case 4:
          this.allProducts = this.allProducts.filter(item => item.Matrice.startsWith('A'));
          break;
      }
    },
    async filterByClass(data) {
      this.mqShallShowLeftSidebar = false;
      if (data.rating != "Tous") {
        switch (data.priceRangeDefined) {
          case '<=10':
            this.allProducts = this.allProducts.filter(item => item.Unit_Price_pharmacien_TTC <= 10);
            break;
          case '10-30':
            this.allProducts = this.allProducts.filter(item => item.Unit_Price_pharmacien_TTC >= 10 && item.Unit_Price_pharmacien_TTC <= 30);
            break;
          case '30-50':
            this.allProducts = this.allProducts.filter(item => item.Unit_Price_pharmacien_TTC >= 30 && item.Unit_Price_pharmacien_TTC <= 50);
            break;
          case '>=50':
            this.allProducts = this.allProducts.filter(item => item.Unit_Price_pharmacien_TTC >= 50);
            break;
        }
      }
    },
    async loadData() {
      try {
        this.showLoading = true;
        const resp = await this.$http.get("_item/itemsByVendor");
        this.allProducts = resp.data;
        this.allProductsWithoutFilters = resp.data;
        this.totalProducts = this.allProducts.length;
        this.products = this.allProducts.slice(0, 9);
        await this.setCategories(); // Wait for setCategories to complete
        this.filters.priceRangeDefined = 'Tous';
        this.filters.categories = 'Tous';
        this.filters.ratings = 1;

        this.showLoading = false;
      } catch (error) {
        console.error('Error loading data:', error);
      }
    },
    async setCategories() {
      try {
        // Create an object to store unique categories by familyDescription
        const categoriesBySubfamily = this.allProducts.reduce((acc, product) => {
          const familyDescription = product.familyDescription || "Tous"; // Default to "Tous" if familyDescription is falsy
          if (!acc[familyDescription]) {
            acc[familyDescription] = new Set(); // Use a Set to ensure uniqueness
          }
          if (product.subFamilyDescription) {
            acc[familyDescription].add(product.subFamilyDescription); // Add categories to the Set
          }
          return acc;
        }, {});

        // Convert the object into an array of objects with distinct categories
        const categoriesArray = Object.entries(categoriesBySubfamily).map(([familyDescription, categoriesSet]) => {
          const sortedCategories = Array.from(categoriesSet).sort((a, b) => a.localeCompare(b));
          return { familyDescription: familyDescription, Categories: sortedCategories };
        });

        // Sort the categoriesArray based on familyDescription
        categoriesArray.sort((a, b) => a.familyDescription.localeCompare(b.familyDescription));

        this.filterOptions.categories = categoriesArray;
      } catch (error) {
        console.error('Error retrieving categories:', error);
      }
    },
    getRatingColor(element) {
      switch (element.Family) {
        case 'MEDICAMENT':
          return 'text-warning';
        case 'PARA':
          return 'text-success';
        case 'ACCESSOIRE':
          return 'text-default';
        default:
          return 'text-warning';
      }
    },
    getRatingClass(element, star) {
      if (element.Matrice.startsWith('A'))
        return 'fill-current';
      if (element.Matrice.startsWith('B') && star <= 3)
        return 'fill-current';
      if (element.Matrice.startsWith('C') && star <= 1)
        return 'fill-current';
    },
  },
  async created() {
    this.loadData();
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>


<style lang="scss" scoped>
.product-img {
  width: 350px;
  height: 250px;
  object-fit: cover;
}

.product-content {
  justify-content: center;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>