<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{ 'show': mqShallShowLeftSidebar }">

        <b-card>

          <h6 class="filter-title top-filter mt-1">
            Catégorie
          </h6>
          <div class="brands">
            <app-collapse class="categor" type="margin">

              <b-form-radio v-model="localFilters.subCategory" name="some-radios" value="Tous"
                @change="triggerFilter">Tous</b-form-radio>

              <app-collapse-item v-for="(item, index) in filterOptions.categories" :key="index"
                :title="item.familyDescription">
                <b-form-radio-group v-model="localFilters.subCategory" class="categories-radio-group" stacked
                  :disabled="showLoading" :options="item.Categories" @change="triggerFilter" />
              </app-collapse-item>
            </app-collapse>
          </div>

        </b-card>
      </div>
    </div>

    <div class="body-content-overlay" :class="{ 'show': mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)" />
  </div>
</template>

<script>

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    showLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localFilters: { ...this.filters }
    }
  },
  methods: {
    triggerFilter() {
      this.$emit('triggerFilter', this.localFilters);
    },
    ratingClick(data) {
      this.localFilters.ratings = data.rating
      this.triggerFilter();
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';

.categor {
  .card-body {
    padding: 1rem;
  }

  .card-header {
    padding: 0.9rem 1.7rem 0.9rem 0.5rem !important;
  }

  .collapse-title {
    // font-weight: bold;
    font-size: 0.95rem !important;
  }
}
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep .custom-control {
    margin-bottom: 0.75rem;
  }
}

@media (max-width: 769px) {
  .top-filter {
    padding-top: 3rem;
    /* Adjust the margin-top value as needed */
  }
}
</style>
